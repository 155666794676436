import React from 'react';
import './Spinner.css';

export default function Spinner() {
  return (
    <div className='container'>
      <img src='/images/spinner.gif' alt='Loading' />
    </div>
  );
}
